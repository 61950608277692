<template>
    <section>
        <v-row>
         <v-col>  <span style="color: #454f7e ; font-size:14px;"> 01-2022-S422 / Insumos / 141115500 papel bond - Resma</span></v-col>
           <v-col md="6" offset-md="3" >
                <v-btn text color="#3ea177"><v-icon>mdi-check-circle-outline </v-icon> Insumo listo</v-btn>
                <v-btn text color="#f09596"><v-icon>mdi-block-helper</v-icon> Descartar insumo</v-btn>
                <v-btn text color=""> <v-icon>mdi-message-text-outline</v-icon> Marcar con observación</v-btn>
           </v-col>        
        </v-row>
        <v-row>
        <v-col  md="8" lg="5">
                <h5>{{ clase }}</h5>
                <h5>Clase:{{clase}} </h5>
                <h5>Mercancia: {{mercancia}}</h5>

                <v-autocomplete
                :items="arrayEstructura"
                label="Estructura presupuestaria"
                outlined
                style="margin-top: 20px"
                ></v-autocomplete>
                <v-autocomplete
                :items="fuenteArray"
                label="Fuente de financiamiento"
                outlined
                style="margin-top: 20px"
                ></v-autocomplete>
                <v-autocomplete
                :items="lineaArray"
                label="Linea de trabajo"
                outlined
                style="margin-top: 20px"
                ></v-autocomplete>
                <v-row>
                    <v-col cols="10" md="8" lg="6">
                        <v-autocomplete
                        :items="datas"
                        label="Unidad de medida"
                        outlined
                        style="margin-top: 20px"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
        </v-col>
        </v-row>
        
    </section>
</template>

<script>

export default{
    name: 'solicitudOBSJefe',
   props:{
    arrayEstructura:{
        type: Array,
        default: () => [1,2,3,4,5,6],
    },
     fuenteArray:{
        type: Array,
        default: () => [1,2,3,4,5,6],
    },
      lineaArray:{
        type: Array,
        
    },
    clase:{
        type: String,
        default: () => '14111510 Papel de imprenta y papel de escribir',
    },
    mercancia:{
        type: String,
        default: () => '14111511 Papel de escritura',
    },
     datas:{
        type: String,
        default: () => '14111511 Papel de escritura',
    }
   }
}
</script>
