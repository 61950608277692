<template>
     <v-row>
      <v-col cols="8" sm="6" md="4" lg="3">
        <p style="color: #454f7e">Agregar insumo</p>

        <h5>{{ clase }}</h5>
        <h5>Clase:{{clase}} </h5>
        <h5>Mercancia: 14111511 Papel de escritura</h5>

        <v-autocomplete
          :items="datas"
          label="Unidad de medida"
          outlined
          style="margin-top: 20px"
        ></v-autocomplete>
      </v-col>
    </v-row>
</template>

<script>
export default {
    name:'selectUnidadComponent',
    props:{
        datas:{
            type: Array,
            required: false,
           default: () => [5, 10, 25, 50, 100],
        },
        clase:{
            type: String,
            default: () => '100141514 Papel de imprenta y de escribir',
        }

    }
}
</script>