<template>
  <section>
    <!--Si 'flag' (boolean), está en true, mostrará el componente: solicitudOBSJefe-->
    <!--Si 'flag' (boolean), está en false, mostrará el componente: selectUnidadComponent-->
    <solicitudOBSJefe v-if="flag = true"/>
    <selectUnidadComponent v-else/>
    <p style="align: center">Precios del mercado</p>
    <v-btn
      depressed
      dark
      style="margin-bottom: 13px"
      @click="dialog = true"
      class="secondary bgWhite--text"
    >
      Agregar
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="documents"
      :items-per-page="5"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver</span>
        </v-tooltip>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>

        <template v-slot:[`footer`]>
            <app-pagination-component
              :totalElements="2"
              @cantidadPage="1"
              @changePage="1"
              :reset="reset"
            />
          </template>

        <template v-slot:[`header.documento`]="{ header }">
          {{ header.text }}
        </template>

         <template v-slot:[`header.precio`]="{ header }">
          {{ header.text }}
        </template>
         <template v-slot:[`header.fecha`]="{ header }">
          {{ header.text }}
        </template>
         <template v-slot:[`header.actions`]="{ header }">
          {{ header.text }}
        </template>
    </v-data-table>

    <v-col>
      <v-row style="justify-content: center; margin: 5px">
        <h4 style="margin-bottom: 13px">Precios del mercado</h4>
      </v-row>
      <v-row style="justify-content: center">
        <p style="display: inline-block">$ 2.45</p>
      </v-row>
      <v-row style="margin: 30px" justify="center">
        <v-chip outlined> $ 2.30 </v-chip>
        <v-divider></v-divider>
        <v-chip outlined> $ 2.50 </v-chip>
      </v-row>
      <p style="align: center">Detalle del insumo</p>

      <v-btn
        depressed
        dark
        style="margin-bottom: 13px"
        @click="clickInsumo"
        class="secondary bgWhite--text"
      >
        Agregar
      </v-btn>
      <v-data-table
        :headers="headers2"
        :items="insumos"
        :items-per-page="5"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>

        <template v-slot:[`header.correlativo`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.mes`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.cantidad`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.precio`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.total`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`header.actions`]="{ header }">
          {{ header.text }}
        </template>

        <template v-slot:[`item.cantidad`]="{item}">
          <v-chip outlined label >
            {{item.cantidad}}
          </v-chip>
        </template>

    <template v-slot:[`body.append`]>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <strong> Total:</strong> $12.50
        </td>
      </tr>
        
      
    </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" md="12">
      <v-textarea
        outlined
        name="input-7-4"
        label="Especificación"
        value="Base 20 blanco tamaño carta presentación caja de resmas de 500 hojas."
      ></v-textarea>
      <v-btn
        text
        color="primary"
        style="margin-bottom: 13px; margin-right: 30px"
        >Volver</v-btn
      >
      <v-btn depressed  dark style="margin-bottom: 13px" class="secondary bgWhite--text">
        Agregar insumo
      </v-btn>
    </v-col>

    <!-- Modal Agregar precio del mercado -->
    <v-dialog v-model="dialog" width="900" persistent>
      <v-card>
        <v-col cols="8" sm="3" md="4" lg="4">
          <v-btn text @click="dialog = false">
            <v-icon>mdi-close </v-icon>
          </v-btn>
        </v-col>

        <v-card-title class="text-h6 primary--text">
          Precios del mercado
        </v-card-title>
        <br />
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                label="Nombre del archivo"
                placeholder="Digite el nombre del archivo"
                :maxLength="200"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-text-field
                outlined
                label="Precio unitario"
                placeholder="Digite el precio unitario"
                :maxLength="200"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="8" md="6" lg="6">
              <v-file-input
                label="Adjuntar archivo"
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col cols="8" sm="3" md="4" lg="4">
            <v-btn class="ma-2" color="secondary" dark> Agregar </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal para solicitar insumo -->
    <v-dialog v-model="dialogInsumo" width="900" persistent>
      <v-card>
        <v-col cols="8" sm="3" md="4" lg="4">
          <v-btn text @click="dialogInsumo = false">
            <v-icon>mdi-close </v-icon>
          </v-btn>
        </v-col>

        <v-card-title class="text-h6 primary--text">
          Solicitud insumo
        </v-card-title>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-select outlined label="Unidad de medida" :maxLength="200">
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" sm="6" md="4" lg="4">
              <v-text-field
                outlined
                label="Cantidad"
                placeholder="Digite la cantidad"
                :maxLength="200"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1 pl-4">Meses</p>

              <v-row>
                <v-checkbox
                  cols="6"
                  class="checkboxStyle"
                  :on-icon="''"
                  :off-icon="''"
                  v-for="item in valores"
                  :key="item.id"
                  :value="item.id"
                  v-model="meses"
                  hide-details
                  color="bgMinsal"
                  style="margin: -3px"
                >
                  <template v-slot:label>
                    <v-alert
                      :outlined="meses.includes(item.id) ? true : false"
                      :color="
                        meses.includes(item.id) ? 'secondary' : 'bgMinsal'
                      "
                      :class="meses.includes(item.id) ? 'checked' : 'unchecked'"
                    >
                      {{ item.name }}
                    </v-alert>
                  </template>
                </v-checkbox>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col cols="8" sm="3" md="4" lg="4">
            <v-btn class="ma-2" color="secondary" dark> Agregar </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import AppPaginationComponent from "../../components/AppPaginationComponent.vue";
import selectUnidadComponent from "./components/selectUnidadComponent.vue";
import solicitudOBSJefe from "./components/solicitudOBSJefeComponent.vue";
export default {
   components: { AppPaginationComponent, selectUnidadComponent, solicitudOBSJefe },
  data() {
    return {
      dialog: false,
      dialogInsumo: false,
      meses: [],
      flag: true,
      valores: [
        { id: 1, name: "Enero" },
        { id: 2, name: "Febrero" },
        { id: 3, name: "Marzo" },
        { id: 4, name: "Abril" },
        { id: 5, name: "Mayo" },
        { id: 6, name: "Junio" },
        { id: 7, name: "Julio" },
        { id: 8, name: "Agosto" },
        { id: 9, name: "Septiembre" },
        { id: 10, name: "Octubre" },
        { id: 11, name: "Noviembre" },
        { id: 12, name: "Diciembre" },
      ],
      datas: ["1", "2", "3", "4"],
      headers: [
        {
          text: "Documento",
          align: "start",
          sortable: false,
          value: "documento",
          class: "secondary bgWhite--text ",
          width:"25.33%",
        },
        { text: "Precio unitario ($)", value: "precio", class: "secondary bgWhite--text ", width:"25.33%", },
        { text: "Fecha carga", value: "fecha", class: "secondary bgWhite--text ",width:"33.33%" },
        { text: "Acciones", align: "center", value: "actions", class: "secondary bgWhite--text ",width:"33.33%" },
      ],
      documents: [
        {
          documento: "Cotización",
          precio: 2.5,
          fecha: "01/05/2022",
        },
        {
          documento: "Cotización",
          precio: 2.5,
          fecha: "01/05/2022",
        },
      ],

      headers2: [
        {
          text: "Correlativo",
          align: "start",
          sortable: false,
          value: "correlativo",
          class: "secondary bgWhite--text ",
        },
        { text: "Mes", value: "mes", class: "secondary bgWhite--text " },
        {
          text: "Cantidad",
          value: "cantidad",
          class: "secondary bgWhite--text ",
        },
        {
          text: "Precio unitario ($)",
          value: "precio",
          class: "secondary bgWhite--text ",
        },
        {
          text: "Total ($)",
          value: "total",
          class: "secondary bgWhite--text ",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          class: "secondary bgWhite--text ",
        },
      ],

      insumos: [
        {
          correlativo: 1,
          mes: "Cotización",
          cantidad: 2.5,
          precio: 50.0,
          total: 2.0,
        },
        {
          correlativo: 2,
          mes: "Cotización",
          cantidad: 10,
          precio: 50.0,
          total: 2.0,
        },
      ],
    };
  },

  methods: {
    click() {
      this.dialog = true;
    },
    clickInsumo() {
      this.dialogInsumo = true;
    },
  },
};
</script>

<style scoped>
hr {
  display: inline-block;
}

.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 12%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0;
  transition: inherit;
  margin-top: 10px;
}
</style>
